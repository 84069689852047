import "./footer.scss";

function Footer() {
  return (
    <footer>
      <div className="footer">
        <p>Copyright 2024 All Rights Reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
