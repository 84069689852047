import React from "react";
import "./header.style.js";
import "./header.scss";

function Header() {
  return (
    <div className="header">
      <div className="logo">
        <h4>Narendra Varada</h4>
      </div>
    </div>
  );
}

export default Header;
